import axios from "axios";
import React, { useState } from "react";
import { Link} from 'react-router-dom';
import { format } from 'date-fns'

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { PatternFormat } from 'react-number-format';


import Header from '../../layout/Header';
import Footer from '../../layout/Footer';

import Modal from 'react-modal';

import checkboxCheckedIcon from '../../assets/img/checkbox-checked.svg';
import checkboxUncheckedIcon from '../../assets/img/checkbox-unchecked.svg';
import { RiCloseCircleLine } from 'react-icons/ri';

import './style.css';


const loginSchema = yup.object().shape({
    userName: yup.string().required("User Name is required"),
    password: yup.string().required("Password is required")
});

const forgotPasswordSchema = yup.object().shape({
    userName: yup.string().required(),
    email: yup.string().email('Invalid email address')
        .required("Email address is required")
    ,
    state: yup.string().required().default(''),
});


const phoneRegExp = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;


const registerSchema = yup.object().shape({
    subject: yup.string().default('www.stashhousedistro.com - Register'),
    yourName: yup.string().required(),
    businessName: yup.string().required(),
    city: yup.string().required(),
    address: yup.string().required(),
    state: yup.string().required().default(''),
    zip: yup.string()
        .required()
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(5, 'Must at least 5 digits'),
    phone: yup.string()
        .required("Business Phone is Required")
        .matches(phoneRegExp, 'Phone number is not valid'),
    cellPhone: yup.string()
        .required("Cell Phone is required")
        .matches(phoneRegExp, 'Phone number is not valid')
       ,
    email: yup.string().email('Invalid email address')
        .required("Email address is required")
        ,
    emailConfirm: yup.string().email()
            .oneOf([yup.ref('email'), null], 'Email addresses do not match'),
    isCBDClient: yup.boolean().oneOf([false],'Message'),
    salesTaxPermit: yup.string()
        .when("isCBDClient", {
            is: true,
            then: () => yup.string().required("Sales Tax Permit is required")
          }),
    salesTaxPermitExpiration: yup.date()
        .when("isCBDClient", {
            is: true,
            then: () => yup.string().required("Expiration date is required")
        }),
    licenseOMMA: yup.string()
        .when("isCBDClient", {
            is: false,
            then: () => yup.string().required()
          }),
    licenseOMMAExpiration: yup.date()
        .when("isCBDClient", {
            is: false,
            then: () => yup.string().required("Expiration date is required")
        }),
    licenseOBNDD: yup.string()
        .when(["state", "isCBDClient"],{
            is: (state, isCBDClient) => {
                if (state === 'Oklahoma' && !isCBDClient) {
                    return true
                }
            },
            then: () => yup.string().required()
    }),
    licenseOBNDDExpiration: yup.date()
        .when(["state", "isCBDClient"],{
            is: (state, isCBDClient) => {
                if (state === 'Oklahoma' && !isCBDClient) {
                    return true
                }
            },
            then: () => yup.string().required("Expiration date is required")
        }),
    userName: yup.string()
        .required("Username is required"),
    password: yup.string()
        .required("Password is required")
        .min(8, 'Password is too short - should be 8 chars minimum.'),
    passwordConfirm: yup.string()
        .oneOf([yup.ref('password'), null], 'Passwords do not match'),
    //newsLetterOptIn: yup.boolean().oneOf([false],'Message'),
    
});

console.log(registerSchema)

const modalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

export function RegisterPage() {
    const [registerButtonText, setRegisterButtonText] = useState("Register");
    const [registerSending, setRegisterSending] = useState(false);
    const [loggingIn, setLoggingIn] = useState(false);
    const [loginButtonText, setLoginButtonText] = useState("Log In");
    const [responseStatus, setResponseStatus] = useState("");
    const [isCBD, setIsCBD] = useState(false);
    const [isNewsLetterOptIn, setIsNewsLetterOptIn] = useState('checked');
    const [licenseLabel, setlicenseLabel] = useState("OMMA");
    const [stateSelect, setStateSelect] = useState("OMMA");

    

    const {
        register: registerLogin,
        handleSubmit: handleSubmitLogin,
        formState: { errors: errorsLogin },
        setError: setErrorLogin,
      } = useForm({
        resolver: yupResolver(loginSchema), // default schema
    });

    const {
        register: registerForgotPassword,
        handleSubmit: handleForgotPassword,
        formState: { errors: errorsForgotPassword },
        reset: resetForgotPassword,
      } = useForm({
        resolver: yupResolver(forgotPasswordSchema), // default schema
    });


    const {
        register,
        handleSubmit,
        setValue,
        resetField,
        reset,
        setError,
        formState: { 
            errors,
         }
      } = useForm({
        resolver: yupResolver(registerSchema), // default schema
    });
    //const baseURL = 'https://ok.stashhousedistro.com';
    const baseURL = 'https://ok.stashhousedistro.com';
    const states = {
        
        'Oklahoma': {
            'website': 'ok.stashhousedistro.com',
            'licenseLabel': 'OMMA',
        },
        'Mississippi': {
            'website': 'ms.stashhousedistro.com',
            'licenseLabel': 'MSDH',
        },
        'Missouri': {
            'website': 'mo.stashhousedistro.com',
            'licenseLabel': 'DHSS',
        },
        'New Jersey': {
            'website': 'nj.stashhousedistro.com',
            'licenseLabel': 'CRC (Retail)',
        },
        'New Mexico': {
            'website': 'nm.stashhousedistro.com',
            'licenseLabel': 'CCD (Retail)',
        },
    }

    const handleStateSelect = (e) =>{
       
        if(e.target.value !== 'Oklahoma'){
            resetField('licenseOBNDD', {keepError: false,});
            resetField('licenseOBNDDExpiration', {keepError: false,})
        }
        if(e.target.value === 'Mississippi'){
            setIsNewsLetterOptIn('checked');
          
        }
        setValue('state', e.target.value, { shouldValidate: true })
        setlicenseLabel(states[e.target.value].licenseLabel);
        setStateSelect(e.target.value);
       
    }

    const handleForgotPasswordStateSelect = (e) =>{
        setValue('forgotPasswordState', e.target.value, { shouldValidate: true });
       
        const forgotPasswordForm = document.getElementById("forgotPasswordForm");
        if (forgotPasswordForm) {
            if(e.target.value !== ''){
                //alert('http://local.'+states[e.target.value].website+'/includes/resetpassword.asp');
                forgotPasswordForm.action = 'https://'+states[e.target.value].website+'/includes/resetpassword.asp';
            }else{
                forgotPasswordForm.action = '#.';
            }
        }
    }

    const handleIsCBDClient = (e) =>{
        if(e.target.checked){
            setIsCBD(true);
            resetField('licenseOMMA', {keepError: false,});
            resetField('licenseOMMAExpiration', {keepError: false,})
            resetField('licenseOBNDD', {keepError: false,});
            resetField('licenseOBNDDExpiration', {keepError: false,})
        }else{
            setIsCBD(false);
            resetField('salesTaxPermit', {keepError: false,});
            resetField('salesTaxPermitExpiration', {keepError: false,})
        }
    }
    
    const handleIsNewsletterOptIn = (e) => {
        if(isNewsLetterOptIn === 'checked'){
            setIsNewsLetterOptIn('');
        }else{
            setIsNewsLetterOptIn('checked');
        }
    }

    const closeMessage = (e) => {
        setRegisterSending(false);
        //resetForm();
    }

    const Switch = props => {
        const { test, children } = props
        // filter out only children with a matching prop
        return children.find(child => {
          return child.props.value === test
        })      
      }

      
    const onLoginSubmit = handleSubmitLogin((_data) => {
        console.log(_data);
        loginUser(_data)
       
    });

    const onForgotPasswordSubmit = handleForgotPassword((_data) => {
        console.log(_data);
        alert("Valid to try rest passsword ");
    });

    const onRegisterSubmit = handleSubmit((_data) => {
        console.log(_data);
        //alert('register');
        registerUser(_data)
        
    });

    async function registerUser(data) {
        setRegisterSending(true);
        setRegisterButtonText("Registering...");
        try {
            var params = new URLSearchParams();
            params.append('yourName', data.yourName);
            params.append('businessName', data.businessName);
            params.append('address', data.address);
            params.append('city', data.city);
            params.append('state', data.state);
            params.append('zip', data.zip);
            params.append('phone', data.phone);
            params.append('cellPhone', data.cellPhone);
            params.append('email', data.email);
            params.append('emailConfirm', data.emailConfirm);
            params.append('licenseOMMA', data.licenseOMMA);
            params.append('licenseOMMAExpiration', data.licenseOMMAExpiration);
            params.append('licenseOBNDD', data.licenseOBNDD);
            params.append('licenseOBNDDExpiration', data.licenseOBNDDExpiration);
            params.append('userName', data.userName);
            params.append('password', data.password);
            params.append('passwordConfirm', data.passwordConfirm);
            params.append('salesTaxPermit', data.salesTaxPermit);
            params.append('salesTaxPermitExpiration', data.salesTaxPermitExpiration);
            params.append('isCBDClient', data.isCBDClient);
            params.append('newsLetterOptIn', data.newsLetterOptIn);
            //setRegisterSending(true);
            //setRegisterButtonText("Register");
            console.log(data);
            console.log(`${baseURL}/ajax/post-website-registration-2023.asp`);
            const response = await axios.post(`${baseURL}/ajax/post-website-registration-2023.asp`, params);

            console.log(response);
            if(response.data.status === 405){
                //alert(response.data.errors.length);
                let input;
                for(input=0; input < response.data.errors.length; input++){
                    setError(response.data.errors[input], {
                        type: "server",
                        message: 'Unkown error with ' + response.data.errors[input],
                    });
                }
                setRegisterSending(false);
                setRegisterButtonText("Register");
            }else if(response.data.status === 409){
                setError('userName',{
                    type: "unavailable",
                    message: 'Username ' + data.userName + ' is unavailable'
                })
                setRegisterSending(false);
                setRegisterButtonText("Register");
            }else{
                reset(data);
                setResponseStatus(response.data.status);
                setRegisterButtonText("Register");
            }
            
        } catch (error) {
            console.log(error);
            setResponseStatus(500);
        }

    }

    async function loginUser(data) {
        setLoggingIn(true);
        setLoginButtonText("Loggin In...");
        try {
            //StabilityCannabis
            //davidsstashhouse13
            let userName = encodeURIComponent(data.userName).replace(/[-_.!~*'()]/g,(c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`,)
            let password = encodeURIComponent(data.password).replace(/[-_.!~*'()]/g,(c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`,);
           
            var params = new URLSearchParams();
            params.append('username', userName);
            params.append('password', password);
            console.log(params);
            //const response = await axios.get(`${baseURL}/admin/api/v2/client/login.asp`, params);
            
            const response = await axios.get(`${baseURL}/admin/api/v2/client/login.asp`, {
                params: {
                    username: userName,
                    password: password
                }
            });
            console.log(response.data);
            if(response.data.Success){
                console.log(response.data.ReturnLink);
                window.location.href = response.data.ReturnLink
                //window.location.href = 'http://local.ok.stashhousedistro.com/admin/pages/auto-login.asp?username=StabilityCannabis&password=davidsstashhouse13&ClientID=434';
                //console.log(response.data.ReturnLink);
            }else{
                setErrorLogin('userName',{
                    type: "error",
                    message: 'Invalid Username and/or password'
                })
                setErrorLogin('password',{
                    type: "error",
                    message: 'Invalid Username and/or password'
                })
                setLoggingIn(false);
                setLoginButtonText("Log In");
            }
           
            //var params = new URLSearchParams();
            //params.append('yourName', userName);


        } catch (error){

        }
    }

    const setTestValues = (e) => {
       
        
        setValue('yourName', 'Matt Snyder');
        setValue('businessName', 'Stash House Distribution');
        setValue('address', '3755 e 48th Pl.');
        setValue('city', 'Tulsa');
        setValue('state', 'Oklahoma');
        setValue('zip', '74135');
        setValue('phone', '(918) 809-4416');
        setValue('cellPhone', '(918) 555-1234');
        setValue('email', 'msnyder@stashhousedisto.com');
        setValue('emailConfirm', 'msnyder@stashhousedisto.com');
        setValue('licenseOMMA', '12345');
        setValue('licenseOMMAExpiration', format(new Date(), 'yyyy-MM-dd'));
        setValue('licenseOBNDD', '12345');
        setValue('licenseOBNDDExpiration', format(new Date(), 'yyyy-MM-dd'));
        setValue('userName', 'msnyder');
        setValue('password', 'B@secamp2023!');
        setValue('passwordConfirm', 'B@secamp2023!');
        e.preventDefault()
        
    }

    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
       
    }

    function closeModal() {
        resetForgotPassword();
        setIsOpen(false);
    }

    return (
        <>
        <Header/>
            
           
            <div id="SHcc6aab27-f4fd-4eef-bd20-50a8fa58eb55" className="containerv2 containerv2--credential-forms containerv2--gutter">
                <div className="containerv2__regions">
                    <div className="containerv2__region containerv2__region--6">

                        <div id="d399d2c7-2902-4fa9-93e6-64136ce47c0a" className="containerv2">
                            <div className="containerv2__regions">
                                <div className="containerv2__region containerv2__region--0">

                                    <div id="554527c3-d565-40df-9e46-ad5d88ae8af1" className="text-content">
                                        <div className="text-content__content">
                                            <h1 className="text-center" >Register</h1>
                                        </div>
                                    </div>
                                    
                                    <form action="#." onSubmit={handleSubmit(onRegisterSubmit)} method="post" className="form--inline-labels" title="Register">
                                        <input type="hidden" {...register("subject")} />
                                        <input type="hidden" name="Send_Group" value="0" {...register("Send_Group")}/>
                                        <div className="formControl">
                                            <label className={errors.yourName ? "input-error" : ""}>
                                                <span className="form__label form__required">Your Name</span>
                                                <div>
                                                <input type="text" placeholder="Name" title="" {...register("yourName")} aria-invalid={errors.yourName ? "true" : "false"} autoComplete="on" 
                                                />
                                                    {errors.yourName?.type === 'required' && <p className="message" role="alert">Name is required</p>}
                                                </div>
                                            </label>

                                        </div>

                                        <div className="formControl">
                                            <label className={errors.businessName ? "input-error" : ""}>
                                                <span className="form__label form__required">Business Name</span>
                                                <div>
                                                    <input type="text" placeholder="Business Name" 
                                                        {...register("businessName")} aria-invalid={errors.businessName ? "true" : "false"}
                                                        title="" autoComplete="on" />
                                                    {errors.businessName?.type === 'required' && <p className="message" role="alert">Business Name is required</p>}
                                                </div>
                                            </label>

                                        </div>
                                        <div className="formControl">
                                            <label className={errors.address ? "input-error" : ""}>
                                                <span className="form__label form__required">Address</span>
                                                <div>
                                                    <input type="text" title="" autoComplete="on" {...register("address")} aria-invalid={errors.address ? "true" : "false"}/>
                                                    {errors.address?.type === 'required' && <p className="message" role="alert">Address is required</p>}
                                                </div>
                                            </label>
                                        </div>
                                        <div className="formControl">
                                            <label className={errors.city ? "input-error" : ""}>
                                                <span className="form__label form__required">City</span>
                                                <div>
                                                    <input type="text" title="" autoComplete="on" 
                                                    {...register("city")} aria-invalid={errors.city ? "true" : "false"}
                                                    />
                                                    {errors.city?.type === 'required' && <p className="message" role="alert">City is required</p>}
                                                </div>
                                            </label>
                                        </div>
                                        <div className="formControl">
                                            <label className={errors.state ? "input-error" : ""}>
                                                <span className="form__label form__required">State</span>
                                                <div>
                                                    <select id="State" 
                                                        {...register("state")}
                                                        
                                                        onChange={handleStateSelect} 
                                                    >
                                                        <option value="">Choose One</option>
                                                        {Object.keys(states).map((key) => {
                                                            return (
                                                            
                                                                <option value={key} data-website={states[key].website} data-licenselabel={states[key].licenseLabel}>{key}</option>
                                                                
                                                            );
                                                        })}
                                                        
                                                    </select>
                                                    <i className="form__select"></i>
                                                    {errors.state && <p className="message" role="alert">Please select a State</p>}
                                                </div>  
                                            </label>
                                        </div>
                                        <div className="formControl">
                                            <label className={errors.zip ? "input-error" : ""}>
                                                <span className="form__label form__required">Zip</span>
                                                <div>
                                                    <input  type="text" title="" autoComplete="on" {...register("zip")} aria-invalid={errors.zip ? "true" : "false"}/>
                                                    {errors.zip && <p className="message" role="alert">Zip is required</p>}
                                                </div>
                                            </label>
                                        </div>
                                        <div className="formControl">
                                            <label className={errors.phone ? "input-error" : ""}>
                                                <span className="form__label form__required">Business Phone</span>
                                                <div>
                                                    <input type="tel" 
                                                    {...register("phone")} aria-invalid={errors.phone ? "true" : "false"}
                                                    
                                                    title="" autoComplete="on" />
                                                    {errors.phone && <p className="message" role="alert">{errors.phone?.message}</p>}
                                                    
                                                </div>
                                            </label>
                                        </div>
                                        <div className="formControl">
                                            <label className={errors.cellPhone ? "input-error" : ""}>
                                                <span className="form__label form__required">Cell Phone</span>
                                                <div>
                                                    <input type="tel"
                                                        {...register("cellPhone")} aria-invalid={errors.cellPhone ? "true" : "false"}
                                                        title="Phone number must be 10 digits long." autoComplete="tel" />
                                                    {errors.cellPhone && <p className="message" role="alert">{errors.cellPhone?.message}</p>}
                                                </div>
                                            </label>
                                        </div>
                                        <div className="formControl">
                                            <label className={errors.email ? "input-error" : ""}>
                                                <span className="form__label form__required">Email</span>
                                                <div>
                                                    <input type="text" placeholder="Email" {...register("email")} aria-invalid={errors.Email ? "true" : "false"}
                                                    title="Email address must match the following format: example@example.com"
                                                    autoComplete="email" />
                                                    {errors.email && <p className="message" role="alert">{errors.email?.message}</p>}
                                                    
                                                </div>
                                            </label>
                                        </div>

                                        <div className="formControl">
                                            <label className={errors.emailConfirm  ? "input-error" : ""}>
                                                <span className="form__label form__required">Confirm Email</span>
                                                <div>
                                                <input name="confirmEmail" type="text" placeholder="Confirm Email"
                                                    {...register("emailConfirm")}
                                                    title="Email address must match the following format: example@example.com"
                                                    autoComplete="email" />
                                                    {errors.emailConfirm && <p className="message" role="alert">{errors.emailConfirm?.message}</p>}
                                                </div>
                                                    
                                            </label>
                                        </div>
                                        <div className="formControl">
                                            <fieldset>
                                                <legend className="form__legend form__switchlegend">CBD/D8 Only Client
                                                </legend>

                                                <div className="form__switch">
                                                        <span>CBD/D8 Only Client</span>
                                                        <div className="form__switch--element">
                                                            <input id="zI_jmXCx" className="visuallyhidden" type="checkbox"
                                                                {...register("isCBDClient")}
                                                                //onChange={handleIsCBDClient()}
                                                                onChange={handleIsCBDClient} 
                                                                />
                                                            <label htmlFor="zI_jmXCx" aria-label="CBD/D8 Only Client"></label>
                                                        </div>
                                                    </div>
                                            </fieldset>
                                        </div>
                                        
                                        {isCBD ?(
                                                <>
                                                    <div className="formControl">
                                                        <label className={errors.salesTaxPermit ? "input-error" : ""}>
                                                            <span className="form__label form__required">Sales Tax Permit</span>
                                                            <div>
                                                                <input type="text" title=""  autoComplete="on" 
                                                                {...register("salesTaxPermit")}
                                                                />
                                                                {errors.salesTaxPermit && <p className="message" role="alert">Sales Tax permit is required</p>}
                                                            </div>
                                                        </label>

                                                    </div>

                                                    <div className="formControl">
                                                        <label className={errors.salesTaxPermitExpiration ? "input-error" : ""}>
                                                            <span className="form__label form__required">Sales Tax Permit Expiration</span>
                                                            <div>
                                                            <input type="date" title=""  autoComplete="on" 
                                                            {...register("salesTaxPermitExpiration")}
                                                            />
                                                            {errors.salesTaxPermitExpiration && <p className="message" role="alert">Expiration date is required</p>}
                                                            </div>
                                                        </label>

                                                    </div>   
                                                </>
                                            ) : (
                                                <>
                                                    {licenseLabel !== '' ?(
                                                        <>
                                                            <div id="98940c54-0855-4a38-80e7-74acdc3d5be3"
                                                                className="form license-state license-oklahoma NonCBDClientField hide-state">
                                                                <label className={errors.licenseOMMA ? "input-error" : ""}>
                                                                    <span className="form__label form__required">License {licenseLabel}</span>
                                                                    <div>
                                                                    <input  type="text" title=""  autoComplete="on" 
                                                                    {...register("licenseOMMA")}
                                                                    />
                                                                    {errors.licenseOMMA && <p className="message" role="alert">License number is required</p>}
                                                                    </div>
                                                                
                                                                </label>

                                                            </div>

                                                            <div id="e61b1389-e4e5-4726-97c5-fc10a81564f9"
                                                                className="form license-state license-oklahoma NonCBDClientField hide-state">
                                                                <label className={errors.licenseOMMAExpiration ? "input-error" : ""}>
                                                                    <span className="form__label form__required">License {licenseLabel} Expiration</span>
                                                                    <div>
                                                                        <input  type="date" title=""  autoComplete="on" 
                                                                        {...register("licenseOMMAExpiration")}
                                                                        />
                                                                        {errors.licenseOMMAExpiration && <p className="message" role="alert">Expiration date is required</p>}
                                                                    </div>
                                                                
                                                                </label>

                                                            </div>
                                                            {stateSelect === 'Oklahoma' ?(
                                                                <>
                                                                    <div id="98940c54-0855-4a38-80e7-74acdc3d5be3"
                                                                        className="form license-state license-oklahoma NonCBDClientField hide-state">
                                                                        <label className={errors.licenseOBNDD ? "input-error" : ""}>
                                                                            <span className="form__label form__required">License OBNDD</span>
                                                                            <div>
                                                                            <input  type="text" title=""  autoComplete="on" 
                                                                            {...register("licenseOBNDD")}
                                                                            />
                                                                            {errors.licenseOBNDD && <p className="message" role="alert">License number is required</p>}
                                                                            </div>
                                                                        
                                                                        </label>

                                                                    </div>

                                                                    <div id="e61b1389-e4e5-4726-97c5-fc10a81564f9"
                                                                        className="form license-state license-oklahoma NonCBDClientField hide-state">
                                                                        <label className={errors.licenseOBNDDExpiration ? "input-error" : ""}>
                                                                            <span className="form__label form__required">License OBNDD Expiration</span>
                                                                            <div>
                                                                                <input  type="date" title=""  autoComplete="on" 
                                                                                {...register("licenseOBNDDExpiration")}
                                                                                />
                                                                                {errors.licenseOBNDDExpiration && <p className="message" role="alert">Expiration date is required</p>}
                                                                            </div>
                                                                        
                                                                        </label>

                                                                    </div>
                                                                </>
                                                                ) : (null)
                                                            }
                                                        </>
                                                        ):(null)
                                                    }
                                                </>
                                            )
                                        }
                                        <div className="formControl">
                                            <label className={errors.userName ? "input-error" : ""}>
                                                <span className="form__label form__required">Username</span>
                                                <div>
                                                    <input type="text" title="" autoComplete="on" 
                                                        {...register("userName")} aria-invalid={errors.userName ? "true" : "false"}
                                                    />
                                                    {errors.userName && <p className="message" role="alert">{errors.userName?.message}</p>}
                                                </div>
                                            </label>
                                        </div>

                                        <div className="formControl">
                                            <label className={errors.password ? "input-error" : ""}>
                                                <span className="form__label form__required">Password</span>
                                                <div>
                                                    <input name="password" type="password" title=""  autoComplete="on" 
                                                        {...register("password")} aria-invalid={errors.password ? "true" : "false"}
                                                    />
                                                    {errors.password && <p className="message" role="alert">{errors.password?.message}</p>}
                                                </div>
                                            </label>
                                        </div>

                                        <div className="formControl">
                                            <label className={errors.passwordConfirm  ? "input-error" : ""}>
                                                <span className="form__label form__required">Confirm Password</span>
                                                <div>
                                                    <input name="passwordConfirm" type="password" title=""  autoComplete="on" 
                                                    {...register("passwordConfirm")}
                                                    />
                                                    {errors.passwordConfirm && <p className="message" role="alert">{errors.passwordConfirm?.message}</p>}
                                                </div>
                                            </label>
                                        </div>
                                        {stateSelect === 'Mississippi' ?(
                                            <>
                                                <div className="formControl">
                                                    
                                                    <div className="form__privacy-checkboxes">
                                                        <input id="xBNanRvg" aria-label="I do not agree" className="visuallyhidden"
                                                            type="checkbox" checked={isNewsLetterOptIn}
                                                            {...register("newsLetterOptIn", )
                                                            }
                                                            />
                                                        <label htmlFor="xBNanRvg" className="form__checkbox form__checkbox--inline" onClick={handleIsNewsletterOptIn}>
                                                            <i className="form__checkbox-icon">
                                                                <img src={checkboxUncheckedIcon} width="24px" height="24px" alt="icon"/>
                                                            </i>
                                                            <i className="form__checkbox-icon">
                                                                <img src={checkboxCheckedIcon} width="24px" height="24px" alt="icon"/>
                                                            </i>
                                                            <span style={{marginTop:0, paddingLeft: '1rem'}}>Stay up to date with company news and product information</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </>
                                        ): (null)
                                        }
                                        <p>By submitting this form, you agree to
                                                            the usage of your personal data in accordance with our <a
                                                                href='/privacy-policy' name='privacyLink' target='_blank'>Privacy
                                                                Policy</a>. You acknowledge that such data will be used for the purpose
                                                            of fulfilling the request in which the form you are submitting is
                                                            applicable. You also agree to the acceptance of occasional follow up
                                                            communication(s) in response to this request, by email, SMS, phone and other
                                                            electronic means. Message and data rates may apply. Reply STOP to end.</p>

                                        <button type="submit" className="button button--align-left c-bg c-bg--primary c-tx c-tx--white">{registerButtonText}</button>
                                         {/* <a href="#." target="" className="button button--align-left" onClick={setTestValues}>Set Test Values</a>  */}
                                        {registerSending ?(
                                            <div className="sending-overlay">
                                                <div className="sending-overlay-inner">
                                                    
                                                    <Switch test={responseStatus}>
                                                        <div value={200} className="form-response-container">
                                                            <h1>Thank you for registering with Stash House.</h1>
                                                            <p>Your account is in review and a sales representative will be contacting you shortly.</p>
                                                            <button className="form-repsonse__close" aria-label="Close main navigation"  onClick={()=> closeMessage()}>
                                                                <RiCloseCircleLine className="App-close"/>
                                                            </button>
                                                        </div>
                                                        <div value={500} className="form-response-container">
                                                            <h1>Oops!  An error has occured</h1>
                                                            <p>We have been notified of this issue and are working on a resolution. </p>
                                                            <p>You can also reach us by phone at <a href="tel:(918) 960-3695">(918) 960-3695</a></p>
                                                            <button className="form-repsonse__close" aria-label="Close main navigation"  onClick={()=> closeMessage()}>
                                                                <RiCloseCircleLine className="App-close"/>
                                                            </button>
                                                        </div>
                                                        <div value={405}></div>
                                                        
                                                    </Switch>
                                                   
                                                       
                                                   
                                                    
                                                </div>
                                            </div>
                                        ) : null}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="containerv2__separator"></div>
                    <div className="containerv2__region containerv2__region--6">

                        <div id="9e12728b-7465-4520-91b9-93fae7a046c4" className="containerv2" >
                            <div className="containerv2__regions">
                                <div className="containerv2__region containerv2__region--0">

                                    <div id="cd3d7340-9c42-4a68-a369-7df8eb080b76" className="text-content">
                                        <div className="text-content__content">
                                            <h1 className="text-center">Login to Order</h1>
                                        </div>
                                    </div>
                                    <form onSubmit={onLoginSubmit} method="post"  title="Sign In" className="form--inline-labels">
                                            
                                        <input type="hidden" name="Subject" value="www.stashhousedistro.com - Sign In" />
                                        
                                        <div>
                                            <div id="f33eab43-5a21-41b4-87e1-291c4aa92e27" className="form">
                                                <label className={errorsLogin.userName ? "input-error" : ""}>
                                                    <span className="form__label">User Name</span>
                                                    <div>
                                                        <input {...registerLogin("userName")} type="text" title="" autoComplete="on" />
                                                        {errorsLogin.userName && <p className="message" role="alert">{errorsLogin.userName?.message}</p>}
                                                    </div>
                                                </label>

                                            </div>

                                            <div id="02ba3955-03ae-4902-b0da-de8f62c16642" className="form">
                                                <label className={errorsLogin.password ? "input-error" : ""}>
                                                    <span className="form__label">Password</span>
                                                    <div>
                                                        <input {...registerLogin("password")} type="password" title="" autoComplete="on" />
                                                        {errorsLogin.password && <p className="message" role="alert">{errorsLogin.password?.message}</p>}
                                                    </div>
                                                </label>

                                            </div>

                                            <div id="ff71d665-23ae-4dbe-9370-602d3c99ce14"
                                                className="containerv2 containerv2--w-full containerv2--h-middle" >
                                                <div className="containerv2__regions">
                                                    <div className="containerv2__region containerv2__region--12">
                                                        
                                                        <a href="#." target="" className="button button--align-left" onClick={openModal}>Forgot Your Password?</a>
                                                        <button type="submit" className="button button--align-left c-bg c-bg--primary c-tx c-tx--white">{loginButtonText}</button>
                                                    </div>

                                                </div>
                                               
                                            </div>
                                            {loggingIn ?(
                                                <div className="sending-overlay">
                                                    <div className="sending-overlay-inner">

                                                    </div>
                                                </div>
                                             ) : null}
                                        </div>
                                        
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <Footer/>

        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            appElement={document.getElementById('root')}
            style={modalStyles}
            portalClassName={"stashhouseModal"}
            overlayClassName={"stashhouseModal__overlay"}
            >
                <h1 className="mb-2">Forgot Password</h1>
                <button className="form-repsonse__close" aria-label="Close main navigation" onClick={closeModal}>
                    <RiCloseCircleLine className="App-close"/>
                </button>
                <form onSubmit={onForgotPasswordSubmit} className="forgot-password-form" id="forgotPasswordForm">
                    <div id="f33eab43-5a21-41b4-87e1-291c4aa92e27" className="form">
                        <div className="mb-2">
                            <label className={errorsForgotPassword.userName ? "input-error" : ""}>
                                <span className="form__label">User Name</span>
                                <div>
                                    <input type="text" {...registerForgotPassword("userName")} title="" autoComplete="on" />
                                    {errorsForgotPassword.userName && <p className="message" role="alert">User Name is required</p>}
                                </div>
                            </label>
                        </div>
                        <div className="mb-2">
                            <label className={errorsForgotPassword.email ? "input-error" : ""}>
                                <span className="form__label">Email Address</span>
                                <div>
                                    <input type="text" {...registerForgotPassword("email")} title="" autoComplete="on" />
                                    {errorsForgotPassword.email && <p className="message" role="alert">Email address is required</p>}
                                </div>
                            </label>
                        </div>
                        <div className="mb-2">
                            <label className={errorsForgotPassword.state ? "input-error" : ""}>
                                <span className="form__label form__required">State</span>
                                <div>
                                    <select id="State" 
                                        {...registerForgotPassword("state")}
                                        
                                        onChange={handleForgotPasswordStateSelect} 
                                    >
                                        <option value="">Choose One</option>
                                        {Object.keys(states).map((key) => {
                                            return (
                                            
                                                <option value={key} data-website={states[key].website} data-licenselabel={states[key].licenseLabel}>{key}</option>
                                                
                                            );
                                        })}
                                        
                                    </select>
                                    <i className="form__select"></i>
                                    {errorsForgotPassword.state && <p className="message" role="alert">Please select a State</p>}
                                </div>  
                            </label>
                        </div>
                        <div className="mb-2 text-center d-flex justify-content-center">
                            <button type="submit" className="button c-bg c-bg--primary c-tx c-tx--white w-100">Reset Password</button>
                        </div>
                    </div>

                </form>
        </Modal>
        </>
    )
}